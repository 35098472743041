import { Component, forwardRef, inject, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { isoDate } from '@core/constants';
import { DateUtils } from '@core/utils/date-utils';
import { BaseInput, controlContainerFactory } from '../base-input';
import { ErrorComponent } from '../error/error.component';
import { FieldsetComponent } from '../fieldset/fieldset.component';

@Component({
    selector: 'ui-v2-date-input',
    standalone: true,
    template: `
        <ui-v2-fieldset [legend]="legend" [label]="label">
            <input
                type="date"
                class="input w-full"
                [class.input-error]="showError"
                [(ngModel)]="innerModel"
                (ngModelChange)="onModelChange($event)"
                (blur)="onBlur()"
                [disabled]="innerDisabled"
            />
            <ui-v2-input-error [showError]="showError" [error]="error"></ui-v2-input-error>
        </ui-v2-fieldset>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateInputComponent),
            multi: true,
        },
        {
            provide: ControlContainer,
            useFactory: controlContainerFactory,
            deps: [
                [new Optional(), new SkipSelf(), FormGroupDirective],
                [new Optional(), new SkipSelf(), NgForm],
            ],
        },
    ],
    imports: [FormsModule, ErrorComponent, FieldsetComponent],
})
export class DateInputComponent extends BaseInput<string> {
    @Input() formControl: FormControl;
    @Input() formControlName: string;

    @Input() legend: string;
    @Input() label: string;

    controlContainer = inject(ControlContainer);

    public writeValue(value: Date): void {
        this.innerModel = DateUtils.isValidDate(value) ? DateUtils.tz(value).format(isoDate) : null;
    }

    public onModelChange(value: string): void {
        this.propagateChange(this.toDateFactory(value));
    }

    private toDateFactory(value: string): Date {
        return DateUtils.toDate(value) ?? null;
    }
}
